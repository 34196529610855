import React from "react";
// import { Link } from 'react-router-dom';
import { Jumbotron, Container, Row, Col } from 'react-bootstrap';

import "./Home.css";

export default function Home() {
  return (
    <div class="jumbo">
      <Jumbotron fluid>

          <h1>Mixonance</h1>
          <p>
            The simple, secure way to share your mixes
          </p>

      </Jumbotron>
      <div class="lander">
        <Container>
        <Row>
          <Col lg={true} d-inline>
        <p>
          When producing and mixing records, how do producers and engineers 
          get mixes to the artists for feedback and approval? Burning cds? 
          Emailing mp3 files? Awkward file-sharing systems? How do you track 
          what went where? How do you compare different mixes? It's a headache!
        </p>
        <p>
          Mixnotes solves these problems with a simple, cloud-based app. 
          Producers upload mixes to Mixnotes, and share them only with 
          the users they want. Users can listen to tracks on Mixnotes 
          anywhere they'd listen to streaming music - at home, in the car, 
          at the gym, on the run. Easily compare mixes on all sorts of speakers 
          and devices. 
        </p>
        <p>
          Each track can have many mixes, so they can be easily compared. 
          Users can write comments on each mix, sharing their feedback with 
          everyone else on the project. No one ever has to download and 
          manage anything. And the producer has final control over 
          who can hear what.  
        </p>
        </Col>
        </Row>
        </Container>
      </div>
    </div>
  );
}

