import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';

import netlifyIdentity from 'netlify-identity-widget';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  // let authorized = netlifyAuth.isAuthenticated
  let authorized = !!netlifyIdentity.currentUser();

  useEffect(() => {
    console.log(["protected route authorized: ", authorized])
  })
  return (
    <Route {...rest} render={
      props => {
        if (authorized) {
          return <Component {...rest} {...props} />
        } else {
          return <Redirect to={
            {
              pathname: '/unauthorized',
              state: {
                from: props.location
              }
            }
          } />
        }
      }
    } />
  )
}

export default ProtectedRoute;