import React from "react";
import { Container, Row } from 'react-bootstrap';
import "./About.css";

export default function About() {
    return (
        <Container>
            <Row>
                <h1>About Mixnotes...</h1>
            </Row>
            <Row>
                <p>
                    I'm Dave Stagner, and I've been producing albums out of my 
                    home studio for over a decade. Something I've struggled with 
                    that whole time is how to get mixes to band members and other 
                    interested parties. I've burned cds, emailed mp3 files, Dropbox,
                    Soundcloud, and more, and nothing has worked well. 
                </p>
                <p>
                    And it's not just a problem for the musicians. As a producer and mixer, I like 
                    to listen to my work on other devices besides studio monitors. 
                    I want to take my mixes for a walk and listen on headphones, or go 
                    for a drive (car speakers are my final arbiter for a good mix). That 
                    means adding my mixes to iTunes, labeling them there, syncing them 
                    to my phone.. again, it's just time-consuming and tedious. 
                </p>
                <p>
                    Mixnotes is my solution for these problems. Producers share mixes 
                    with performers and others through a simple, mobile-friendly web app. 
                    No one has to download and manage files on their own. Different mixes 
                    of the same track can be easily compared. Listeners can write notes on 
                    mixes, right in the app, for everyone else to see. And it's secure from 
                    the start - only those who have been invited to a project can listen to 
                    it. Mixnotes is not intended to be a public streaming platform and 
                    never will be, so there's nothing complicated about ensuring the privacy 
                    of your mixes. 
                </p>
                <h2>Sounds great, how do I get it?</h2>
                <p>
                    Mixnotes is currently in alpha-stage development. I'm using it to help 
                    produce two different albums I'm working on, so I'm eating my own dog food,
                    with feedback (haha) from my own bandmates and collaborators. 
                </p>
                <p>
                    It will be available soon for beta customers. If you have a project coming 
                    up and you're interested in beta testing, please get in touch with me, 
                    dave@spnz.org. Thanks! 
                </p>
            </Row>
        </Container>
    );
}