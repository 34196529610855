import React from 'react';
// import './App.css';

import { Switch, Route } from 'react-router-dom';
import { Container, Navbar, Nav } from 'react-bootstrap';

import Error from './Error';
import Home from './components/Home';
import Dashboard from './components/Dashboard';
import About from './components/About';
// import Mprojects from './containers/Mprojects';
// import MixProject from './containers/MixProject';
import Unauthorized from './Unauthorized';
import ProtectedRoute from './ProtectedRoute';

// class App extends Component {
function App() {

  return (
    <Container fluid>
      <Navbar bg="light" expand="lg">
        <Navbar.Brand href="/">Mixnotes</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="/dashboard">Dashboard</Nav.Link>
            <Nav.Link href="/about">About</Nav.Link>
            <Nav.Link href="/projects">Projects</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <main>
          <Switch>
            <ProtectedRoute path="/dashboard" component={Dashboard} />
            <Route path="/" component={Home} exact />
            <Route path="/about" component={About} />

            <Route exact path='/unauthorized' component={Unauthorized} />
            <Route component={Error} />      
          </Switch>
      </main>
    </Container>
  );
}

export default App;
