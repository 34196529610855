import React from "react";
import { Row, Table, Container } from "react-bootstrap";

export default function Dashboard() {

  return (
    <div class="dashboard">
      <Container>
        <Row>
          <h1>Dashboard</h1>
        </Row>
        <Row>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Artist</th>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>foo</td>
                </tr>
            </tbody>
          </Table>
        </Row>
      </Container>
    </div>
  )
}