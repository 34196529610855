import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import netlifyIdentity from 'netlify-identity-widget';
// import netlifyAuth from './netlifyAuth';
import App from './App';

window.netlifyIdentity = netlifyIdentity;
// You must run this once before trying to interact with the widget
netlifyIdentity.init();

// netlifyIdentity.init();
// netlifyAuth.initialize();

ReactDOM.render (
  <BrowserRouter>
      <App />
  </BrowserRouter>, 
  document.getElementById('root')
);

// ReactDOM.render (
//     <App />, 
//   document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
